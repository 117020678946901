.skills__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    justify-content: center;
    max-width: 968px;
    margin: 0 auto;
}

.skills__content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 1.5rem;
    border-radius: 1.25rem;
    width: 100%;
    box-sizing: border-box;
}

.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
}

.skills__box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.skills__group {
    display: contents;
}

.skills__data {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
}

.skills__name {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    line-height: 1.2;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .skills__container {
      grid-template-columns: max-content;
      row-gap: 2rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .skills__container {
      grid-template-columns: 1fr;
      width: calc(100% - 2rem);
      margin-left: auto;
      margin-right: auto; /* Changed from 10rem to auto for balance */
    }
    
    .skills__content {
      margin: 0 0 1.5rem; /* Removed left/right margin */
      padding: 1.5rem;
    }
    
    .skills__box {
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
    }
  }
  
  @media screen and (max-width: 350px) {
    .skills__container {
      grid-template-columns: 1fr;
      padding: 0 1rem;
    }
    
    .skills__content {
      padding: 1.5rem;
    }
    
    .skills__box {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    
    .skills__name {
      font-size: var(--smaller-font-size);
    }
    
    .skills__data {
      padding: 0.25rem 0;
    }
  }
  